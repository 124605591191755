import httpUtil from "@/utils/httpUtil";

/**获取楼宇列表 */
export const getBuildManageList = (params) => httpUtil.post("/api/crmPc/property/getBuildManageList", params)

/**获取楼宇详情 */
export const getBuildManage = (id) => {
    let params = { buildId: id };
    return httpUtil.post("/api/crmPc/property/getBuildManage", params)
}

/**新增/编辑楼宇 */
export const setBuildManage = (params) => httpUtil.post("/api/crmPc/property/setBuildManage", params)

/**字典 */
export const getByDictType = (params) => httpUtil.post("/api/crmPc/sysDict/getByDictType", params)

/**获取房屋列表 */
export const getHouseManageList = (params) => httpUtil.post("/api/crmPc/property/getHouseManageList", params)

/**新增/编辑房屋 */
export const setHouseManage = (params) => httpUtil.post("/api/crmPc/property/setHouseManage", params)

/**删除房屋 */
export const delHouseManage = (params) => httpUtil.post("/api/crmPc/property/delHouseManage", params)

/**出租列表 */
export const getHouseHireList = (params) => httpUtil.post("/api/crmPc/property/getHouseHireList", params)

/**新增/编辑出租 */
export const setHouseHire = (params) => httpUtil.post("/api/crmPc/property/setHouseHire", params)

/**填写资料批量导入 */
export const setHouseManageList = (params) => httpUtil.post("/api/crmPc/property/setHouseManageList", params)

/**获取园区进驻商家列表 */
export const getParkIntoCompanyList = (params) => httpUtil.post("/api/crmPc/property/getParkIntoCompanyList", params)

/**获取园区进驻商家详情 */
export const getParkIntoCompany = (params) => httpUtil.post("/api/crmPc/property/getParkIntoCompany", params)

/**审核进驻商家 */
export const updateParkIntoCompany = (params) => httpUtil.post("/api/crmPc/property/updateParkIntoCompany", params)

/**进驻分类 */
export const getParkShopTypeLinkList = async params => httpUtil.post("/api/crmPc/property/getParkShopTypeLinkList", params);

/**新增关联分类 */
export const insertParkShopTypeLink = async params => httpUtil.post("/api/crmPc/property/insertParkShopTypeLink", params);

/**修改关联分类 */
export const updateParkShopTypeLink = async params => httpUtil.post("/api/crmPc/property/updateParkShopTypeLink", params);

/**设置进驻商家配置 */
export const setParkIntoOption = (params) => httpUtil.post("/api/crmPc/property/setParkIntoOption", params)

/**获取设置进驻商家配置 */
export const getParkIntoOption = (params) => httpUtil.post("/api/crmPc/property/getParkIntoOption", params)

/**修改进驻服务大厅申请信息 */
export const setParkIntoCompany = (params) => httpUtil.post("/api/crmPc/property/setParkIntoCompany", params)

/**修改进驻周边商家申请信息 */
export const setParkIntoShop = (params) => httpUtil.post("/api/crmPc/property/setParkIntoShop", params)

/**获取单元 房屋 */
export const getBuildHouseList = (params) => httpUtil.post("/api/crmPc/property/getBuildHouseList", params)

/**获取房屋统计数据 */
export const getBuildTotalData = (params) => httpUtil.post("/api/crmPc/property/getBuildTotalData", params)

/**获取会议室预约列表 */
export const getPropertySubscribeList = (params) => httpUtil.post("/api/crmPc/property/getPropertySubscribeList", params)

/**修改会议室预约信息 */
export const updatePropertySubscribe = (params) => httpUtil.post("/api/crmPc/property/updatePropertySubscribe", params)

/**通过ID查询物业报事 */
export const getPropertyThingById = (params) => httpUtil.post("/api/crmPc/property/getPropertyThingById", params)

/**查询物业报事列表 */
export const getPropertyThingList = (params) => httpUtil.post("/api/crmPc/property/getPropertyThingList", params)

/**修改物业报事 */
export const updatePropertyThing = (params) => httpUtil.post("/api/crmPc/property/updatePropertyThing", params)

/**活动订单 */
export function getActivityRegisterOrderList(params) {
    return httpUtil.post("/api/crmPc/activityEvent/getActivityRegisterOrderList", params)
}

/**退款 */
export function updateRefundState(params) {
    params.refundState = 3;
    return httpUtil.post("/api/crmPc/activityEvent/updateRefundState", params)
}

/**商品订单 */
export function selectOrdersInfoPc(params) {
    return httpUtil.post("/api/crmPc/orders/selectOrdersInfoPc", params)
}

/**进驻商家订单 */
export function getParkIntoOrderList(params) {
    return httpUtil.post("/api/crmPc/property/getParkIntoOrderList", params)
}