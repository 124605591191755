<template>
    <div class="view pa24">
        <div>
            <el-tabs v-model="webType">
                <el-tab-pane label="房屋管理" name="houseTable"></el-tab-pane>
                <el-tab-pane label="预览信息管理" name="infoTable"></el-tab-pane>
                <el-tab-pane label="二维码管理" name="codeTable"></el-tab-pane>
            </el-tabs>
        </div>
        <div v-if="webType==='houseTable'">
            <house-table/>
        </div>
        <div v-if="webType==='infoTable'">
            <info-table/>
        </div>
        <div v-if="webType==='codeTable'">
           <code-table/>
        </div>
    </div>
</template>

<script>
import codeTable from "./pages/codeTable";
import infoTable from "./pages/infoTable";
import houseTable from "./pages/houseTable";
export default {
    name: "house",
    components: {
        codeTable,
        houseTable,
        infoTable
    },
    data() {
        return {
            webType:"houseTable",
        };
    },
    filters: {

    },
    created() {

    },
    methods: {
        
    },
};
</script>

<style lang="scss" scoped>
.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>